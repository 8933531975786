import React from "react";
import Navbar from "../components/navbar/navbar";
import Heading from "../components/heading/heading";
import Footer from "../components/footer/footer";
import styles from "./css/completedProjects.module.css"
import GoToTop from "../components/goToTop/goToTop";


const tableInfo = [
    {
        "number": "1",
        "name": "Изготвяне на работен проект, доставка, монтаж и пуск в експлоатация на ПСОВ за 500 Е.Ж.",
        "contractor": "Гри Груп ЕАД, гр. Варна",
        "typeObject": "Ваканционно"
    },
    {
        "number": "2",
        "name": "Изготвяне на работен проект, доставка, монтаж и пуск в експлоатация на ПСОВ от млекопреработвателно предприятие",
        "contractor": "„Млечен рай“ ЕООД, гр. Враца",
        "typeObject": "Мандра"
    },
    {
        "number": "3",
        "name": "Изготвяне на работен проект, доставка, монтаж и пуск в експлоатация на ПСОВ за 3750 Е.Ж.",
        "contractor": "„Евросилекс“ ООД, гр. Варна",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "4",
        "name": "Изготвяне на работен проект за ПСОВ и реконструкция на канализация на с. Габер",
        "contractor": "Община Драгоман",
        "typeObject": "Канализация и ПСОВ на с. Габер, общ"
    },
    {
        "number": "5",
        "name": "Изготвяне на работен проект за реконструкция на ПСОВ на предприятие от текстилната промишленост",
        "contractor": "„Марицатекс“ АД, гр. Пловдив",
        "typeObject": "Текстилна промишленост"
    },
    {
        "number": "6",
        "name": "Изготвяне на работен проект, доставка, монтаж и пуск на ПСОВ от винопреработвателно предприятие",
        "contractor": "„СИС Индъстрис“, с.Венец",
        "typeObject": "Винарна"
    },
    {
        "number": "7",
        "name": "Изготвяне на работен проект, доставка, монтаж и пуск на ПСОВ от завод за преработка на битови отпадъци",
        "contractor": "Консорциум- Екологичен завод“, гр. Пловдив",
        "typeObject": "Завод за преработка"
    },
    {
        "number": "8",
        "name": "Изготвяне на работен проект, доставка, монтаж и пуск на ПСОВ на Жилищен комплекс за 200 Е.Ж.",
        "contractor": "„ЗЕМ проект “ ООД, гр. Варна",
        "typeObject": "Жилищен  комплекс "
    },
    {
        "number": "9",
        "name": "Изготвяне на работен проект, доставка, монтаж и пуск на ПСОВ на Жилищен комплкес за 200 Е.Ж.",
        "contractor": "„Пи Енд Ди Строй“ ООД, гр. София",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "10",
        "name": "Изготвяне на работен проект, доставка, монтаж и пуск на ПСОВ на Жилищен комплекс за 300 Е.Ж.",
        "contractor": "„Бритиш Бългериън Инвестмънт“ ООД, гр. Варна",
        "typeObject": "Жилище  комплекс "
    },
    {
        "number": "11",
        "name": "Проектиране, доставка и монтаж на оборудване за корекция на PH на отпадъчни води",
        "contractor": "„Балгар“ ООД, гр. Пазарджик",
        "typeObject": "Хранително-вкусова промишленост"
    },
    {
        "number": "12",
        "name": "Изготвяне на работен проект, доставка, монтаж и пуск на ПСОВ на Жилищен комплекс за 200 Е.Ж.",
        "contractor": "A-M-N-D Ltd., Боровец",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "13",
        "name": "Изготвяне на работен проект, доставка, монтаж и пускна ПСОВ за 50 Е.Ж., предприятие за рециклиране начерна и бяла техника",
        "contractor": "„Надин Метал“ ЕООД, грСофия",
        "typeObject": "Предприятие зарециклиране начерна и бяла техника"
    },
    {
        "number": "14",
        "name": "Изготвяне на работен проект, доставка, монтаж и пускна ПСОВ за 400 Е.Ж., завод за производство на яйченмеланж",
        "contractor": "„Ово-Бул“ ООД, гр.Плевен",
        "typeObject": "Завод запроизводство наяйчен меланж"
    },
    {
        "number": "15",
        "name": "Изготвяне на работен проект, доставка, монтаж и пускна ПСОВ на жилищен комплекс за 150 Е.Ж.",
        "contractor": "„Полас България“, гр.София",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "16",
        "name": "Изготвяне на работен проект, доставка, монтаж и пускна ПСОВ на жилищен комплекс за 200 Е.Ж.",
        "contractor": "„Екопроект С“, гр. Варна",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "17",
        "name": "Удобрение на работен проект за ПСОВ на гр. Троян",
        "contractor": "“VON Nordenskjold”Гр.Троян",
        "typeObject": "ПСОВ на гр. Троян"
    },
    {
        "number": "18",
        "name": "Доставка на машинно технологично оборудване наПСОВ; Пукс и наладка; Въвеждане в експлоатация;Пробонабиране и анализи; Авторски надзор",
        "contractor": "” Но Менс Ленд ” ООДС.Дамяница",
        "typeObject": "Винарна"
    },
    {
        "number": "19",
        "name": "Проектиране на пречиствателна станция запроизводствени и битови отпадъчни води",
        "contractor": "“ШАТО Велико Търново”Гр.Велико Търново",
        "typeObject": "Винарна"
    },
    {
        "number": "20",
        "name": "Проектиране, доставка и шеф-монтаж напречиствателна станция за кланица",
        "contractor": "“Месни продукти” ООД,с.Здравец Търговище",
        "typeObject": "Кланица"
    },
    {
        "number": "21",
        "name": "Проектиране и доставка на модулна пречиствателнастанция за отпадъчни води",
        "contractor": "“Петър Мавров” ООДс. Горица",
        "typeObject": "Вилно селище"
    },
    {
        "number": "22",
        "name": "Доставка и шеф-монтаж на ПСПВ – Омекотителнаинсталация за пароцентрала",
        "contractor": "МБАЛ “Проф. Д-р Ст.Кирович”АДГр.Стара Загора",
        "typeObject": "Болница"
    },
    {
        "number": "23",
        "name": "Проектиране, доставка, шеф-монтаж на модулниПСОВ с остраняване на азот и фосфор – 50 Е.Ж. – 2 бр.и 100 Е.Ж. – 1 бр",
        "contractor": "“Е&С Имоти” ООДГр.Смолян",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "24",
        "name": "Проектиране , доставка и шеф-монтаж на модулнапречиствателна станция за битови отпадъчни води состраняване на азот и фосфор за 1000 Е.Ж.",
        "contractor": "“Строителна фирма Рила”ЕООД, с. Шишковци",
        "typeObject": "ПСОВ на с.Шишковци, общ.Кюстендил"
    },
    {
        "number": "25",
        "name": "Проектиране на ПСОВ на с. Мурсалево, общ. Кочериново",
        "contractor": "община Кочериново",
        "typeObject": "ПСОВ на с.Мурсалево, общ.Кочериново"
    },
    {
        "number": "26",
        "name": "Проектиране, доставка и монтаж на ПСОВ за 150 Е.Ж.",
        "contractor": "Спец.болница задолекуване ирехабилитация, РайонПанчерево",
        "typeObject": "Болница"
    },
    {
        "number": "27",
        "name": "Проектиране, доставка и шеф-монтаж на модулнаПСОВ за 50 Е.Ж.",
        "contractor": "“БИ СИ ЕН Резидънсис”ООД, гр. София",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "28",
        "name": "Работни проекти за „Доставка на оборудване истроителство за ПСОВ – 200 м3/ден и 400 м3/ден заплощадката на АЕЦ Белене“",
        "contractor": "“ОРГЕНЕРГОСТРОЙ”ЕАД,АЕЦ Белене",
        "typeObject": "АЕЦ Белене"
    },
    {
        "number": "29",
        "name": "Предпроектно проучване; съдействие за издаване наразрешително за заустване, изготвяне на работенпроект, доставка, шеф-монтаж, пуск и наладка наПСОВ за 500 Е.Ж. с остраняване на азот и фосфор",
        "contractor": "“БОРА – РВБ” 00ДС.Осеново, гр. Варна",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "30",
        "name": "Преинвестиционно проучване и изготвяне на работенпроект на ПСОВ на с. Черниче, общ. Симитли",
        "contractor": "Община Симитли",
        "typeObject": "ПСОВ на с.Черниче, общ.Симитли"
    },
    {
        "number": "31",
        "name": "Доставка на модулна ПСОВ за 500 Е.Ж.",
        "contractor": "“Софт – Ю” ООДС.Егрек, гр. Крумовград",
        "typeObject": "ПСОВ на с. Егрег,общ. Крумовград"
    },
    {
        "number": "32",
        "name": "Изготвяне на технически проект за реконструкция ирехабилитация на водоснабдителната мрежа исъоръжения на с. Глогово, общ. Тетевен",
        "contractor": "Общ. Тетевен",
        "typeObject": "Водоснабдяване нас. Глогово, общ.Тетевен"
    },
    {
        "number": "33",
        "name": "Изработване на технически проект за„Канализационна система и съоражения” и за ПСОВна с. Раковски, общ. Каварна",
        "contractor": "Община Каварна",
        "typeObject": "ПСОВ на с.Раковски, общ.Каварна"
    },
    {
        "number": "34",
        "name": "Изготвяне на работни проекти за ПСОВ на гр.Оряхово и с. Селановци, общ. Оряхово",
        "contractor": "Астра 2005 ЕООД, гр.Благоевград",
        "typeObject": "ПСОВ на гр.Оряхово и с.Селановци, общ.Оряхово"
    },
    {
        "number": "35",
        "name": "Изготвяне на технически проект за „Изграждане наПСОВ и канализация на село Дебрен, общ. Гърмен",
        "contractor": "Общ. Гърмен",
        "typeObject": "ПСОВ на с. Дебрен,общ. Гърмен"
    },
    {
        "number": "36",
        "name": "Изготвяне на работен проект – Реконструкция нанапорен водопровод на ПС Неделище І-ви подем доПС Неделище ІІ-ри подем на гр. Драгоман, общ.Драгоман",
        "contractor": "Община Драгоман",
        "typeObject": "Водоснабдяване нагр. Драгоман, общ.Драгоман"
    },
    {
        "number": "37",
        "name": "“Изготвяне на план за собствен мониторинг наотпадни води” и “План за действие в аварийниситуации на площадката на строителство на АЕЦ“Белене”",
        "contractor": "“ЗАД Атомстройекспорт –клон Белене” КЧТ",
        "typeObject": "АЕЦ Белене"
    },
    {
        "number": "38",
        "name": "Доставка на модулна ПСОВ за 500 Е.Ж. на с. Студенкладенец, общ. Крумовград",
        "contractor": "“Дурал” ООД,с. Студен кладенец",
        "typeObject": "ПСОВ на с. Студенкладенец, общ.Крумовград"
    },
    {
        "number": "39",
        "name": "Доставка на модулна ПСОВ за 150 Е.Ж.",
        "contractor": "“Когер енд Партнерс”ООД, гр. София",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "40",
        "name": "Изготвяне на геоложко проучване; Геодезическозаснемане; Хидроложко прочуване; Работен проект заизграждане на комбинирана канализационна мрежа нагр. Две Могили; Изготвяне на работен проект зареконструкция на вътрешната водопроводна мрежанагр. Две Могили; Изготвяне на технически проект заизграждане на градска пречиствателна станция заотпадъчни води на гр. Две Могили за 5300 Е.Ж.",
        "contractor": "“ЕКОПРО КОНТРОЛ”ООД, гр. Две Могили",
        "typeObject": "Водоснабдяване,канализация иПСОВ на гр. ДвеМогили"
    },
    {
        "number": "41",
        "name": "Интегриран воден проект за реконструкция наводоснабдителна мрежа, изграждане наканализационна мрежа и ПСОВ на с. Галата, общ.Тетевен",
        "contractor": "Община Тетевен",
        "typeObject": "Водоснабдяване,канализация иПСОВ на с. Галата,общ. Тетевен"
    },
    {
        "number": "42",
        "name": "Техническа помощ за предпроектни проучвания,финансови анализи, анализ на ползи-разходи, работнопроектиране, ОВОС, изготвяне на тръжни документиза строителство и надзор и форма за кандидатстванеза финансиране на интегриран инвестиционен проектза подобряване и развитие на инфраструктурата запитейни и отпадъчни води на гр. Тетевен",
        "contractor": "Община Тетевен",
        "typeObject": "Водоснабдяване,канализация иПСОВ на гр.Тетевен, общ.Тетевен"
    },
    {
        "number": "43",
        "name": "Изготвяне на технически проект, доставка и шефмонтаж на ПСОВ за 200 Е.Ж.",
        "contractor": "“Лайтхаус Голф Ризорт”АД, Гр.Каварна",
        "typeObject": "Ваканционноселище"
    },
    {
        "number": "44",
        "name": "Доставка и шеф- монтаж на ПСОВ за 150 Е.Ж.",
        "contractor": "“Осъм Инвест Консулт”ООД, Гр. Троян",
        "typeObject": "Хотелски комплекс"
    },
    {
        "number": "45",
        "name": "Изготвяне на работен проект по всички частисъгласно изискванията по ЗУТ и наредбите към него,вкл. и ПБЗ; Доставка и монтаж на два броя модулниПСОВ и машинно -технологично оборудване;Въвеждане в експлоатация на пречиствателносъоръжение за 500 Е.Ж.",
        "contractor": "“Стенстрой-Инженеринг”ЕООД, гр. София",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "46",
        "name": "Проектиране, доставка и шеф-монтаж на модулнаПСОВ за 200 Е.Ж.",
        "contractor": "ЕТ “Шейнур Моллов -Нуртаб“, гр. Гоце Делчев",
        "typeObject": "Ваканционноселище"
    },
    {
        "number": "47",
        "name": "Изготвяне на работен проект, доставка на модулнаПСОВ и технологично оборудване за 200 Е.Ж.",
        "contractor": "“Масон инженеринг”ООД,гр. София",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "48",
        "name": "Изготвяне на работен проект и доставка на модулнаПСОВ за 150 Е.Ж.",
        "contractor": "“Арнаудовстрой” ООД,гр. Несебър",
        "typeObject": "Хотелски комплекс"
    },
    {
        "number": "49",
        "name": "Изготвяне на технически проект; Доставка на модулнаПСОВ с включено машинно-технологичнооборудване; Шеф монтаж и въвеждане в експлоатация",
        "contractor": "“ЕН ДИ БИ” ЕООД, с.Рибарица, общ. Тетевен",
        "typeObject": "Хотелски комплекс"
    },
    {
        "number": "50",
        "name": "Изготвяне на технически проект за ПСОВ за 1300 Е.Ж.на с. Майско, общ. Елена",
        "contractor": "“Места Инженеринг”ООД,Гр. София",
        "typeObject": "ПСОВ на с. Майско,общ. Елена"
    },
    {
        "number": "51",
        "name": "Проектиране, доставка, монтаж на модулна ПСОВ за200 Е.Ж. с. Казичене, местност Промишлена зона –Север",
        "contractor": "“Инвестра” АД, гр.София",
        "typeObject": "Индустриален парк"
    },
    {
        "number": "52",
        "name": "Изготвяне на Работен проект и доставка на модулнаПСОВ за 50 Е.Ж.",
        "contractor": "ЕТ “Лъвида Л.Аврамов”,гр. София",
        "typeObject": "Жилищна сграда"
    },
    {
        "number": "53",
        "name": "Изготвяне на предпоректно проучване, изготвяне надокументи за преценка на необходимостта от ОВОС,изготвяне на технически проект, доставка на ПСОВ за50 Е.Ж.",
        "contractor": "“ПиМ Груп” ООД, гр.София",
        "typeObject": "Жилищна сграда"
    },
    {
        "number": "54",
        "name": "Доставка, монаж и въвеждане в експоатация на ПСОВза мандра",
        "contractor": "ПИП Трейд” ООДс.Давидово",
        "typeObject": "Мандра"
    },
    {
        "number": "55",
        "name": "Изготвяне на технически проект, доставка, монтаж ипускане в екплоатация на модулна ПСОВ за 50 Е.Ж.",
        "contractor": "”Билта“ ЕАД, гр. София",
        "typeObject": "Жилищна сграда"
    },
    {
        "number": "56",
        "name": "Изготвяне на технически проект, доставка, монтаж ипускане в екплоатация на модулна ПСОВ за 150 Е.Ж.",
        "contractor": "„ЛВ Глоуб“ ООД, гр.Велико Търново",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "57",
        "name": "Изготвяне на технически проект, доставка, монтаж ипускане в екплоатация на модулна ПСОВ за 50 Е.Ж.",
        "contractor": "„Елтрейд“ ООД, гр.София",
        "typeObject": "Хотелски комплекс"
    },
    {
        "number": "58",
        "name": "Изготвяне на технически проект, доставка, монтаж ипускане в екплоатация на ПСОВ",
        "contractor": "„Милве“ АД, гр. БялаСлатина",
        "typeObject": "Фармацефтичензавод"
    },
    {
        "number": "59",
        "name": "Изготвяне на предпоректно проучване, изготвяне надокументи за преценка на необходимостта от ОВОС,изготвяне на документи за разрешително за заустване,изготвяне на технически проект, доставка и монтаж наПСОВ",
        "contractor": "„Провадсол“ ЕАД, гр.Провадия",
        "typeObject": "Добив на сол"
    },
    {
        "number": "60",
        "name": "Изготвяне на преценка необходимостта от ОВОС,изготвяне на предпроектни проучвания, изготвяне натехнически проект, доставка и шеф-монтаж на ПСОВза 1400 Е.Ж.",
        "contractor": "“Гемар” ООД С. Крапец",
        "typeObject": "Ваканционноселище"
    },
    {
        "number": "61",
        "name": "Изготвяне на прединвестиционно проучване заизпълнение на проект „Подобряване на басейна на р.Камчия чрез изпълнение на интегриран воден проектза с. Люляково, община Руен, област Бургас“",
        "contractor": "Община Руен",
        "typeObject": "ПСОВ на с.Люляково, общ.Руен"
    },
    {
        "number": "62",
        "name": "Изготвяне на технически проект, доставка, монтаж ипускане в екплоатация на модулна ПСОВ за 100 Е.Ж.",
        "contractor": "„Конов“ ЕООД, с.Рибарица",
        "typeObject": "Хотелски комплекс"
    },
    {
        "number": "63",
        "name": "Изготвяне на Работен проект за обект „Реконструкцияи модернизация на пречиствателна станция заотпадъчни води“",
        "contractor": "“Дуропак Тракия” АД, гр.Пазарджик",
        "typeObject": "Хартиенапромишленост"
    },
    {
        "number": "64",
        "name": "Изготвяне на идеен проект за обект „Подобряване набасейна на р. Камчия чрез изпълнение на интегриранводен проект за с. Люлякова, община Руен, областБургас“",
        "contractor": "Община Руен",
        "typeObject": "ПСОВ на с.Люляково, общ.Руен"
    },
    {
        "number": "65",
        "name": "Изготвяне на работен проект, доставка, монтаж ипуска в експлоатация на ПСОВ",
        "contractor": "„Романтика Форест“ АД,гр.Пазарджик",
        "typeObject": "Ваканционноселище"
    },
    {
        "number": "66",
        "name": "Изготвяне на Технически проект, доставка, монтаж ипуска в експлоатация на ПСОВ",
        "contractor": "“Булмаркет ДМ” АД, гр.Русе",
        "typeObject": "Производство набиодизел"
    },
    {
        "number": "67",
        "name": "Изготвяне на обследване, технически проект,доставка, монтаж и пуск на машинно-технологичнооборудване за реконструкция на ПСОВ",
        "contractor": "“Колхида – Сливен” АД,гр. Сливен",
        "typeObject": "Пране на вълна"
    },
    {
        "number": "68",
        "name": "Изготвяне на прединвестиционни проучвания заводоснабдителна и канализационна мрежанатериторията на с. Мусомище , общ. Г. Делчев",
        "contractor": "Община Гоце Делчев",
        "typeObject": "ВиК и ПСОВ на с.Мусомища, общ.Гоце Делчев"
    },
    {
        "number": "69",
        "name": "Изготвяне на прединвестиционни проучвания заводоснабдителна и канализационна мрежа и ПСОВ натериторията на с. Баня, общ. Разлог",
        "contractor": "Община Разлог",
        "typeObject": "Водоснабдяване,канализация иПСОВ на с. Баня,общ. Разлог"
    },
    {
        "number": "70",
        "name": "Изготвяне на техн. проект за пречиствателна станцияза отпадни води по проект: „Подобряване и развитиена инфраструктурата за питейни и отпадъчни води в с.Трилистник, общ. „Марица“, област Пловдив“",
        "contractor": "Община Марица",
        "typeObject": "ПСОВ на с.Трилисник, общ.Марица"
    },
    {
        "number": "71",
        "name": "Актуализация на предварителни проучвания иизготвяне на идеен проект на ПСОВ на гр. Своге исъздаване на план за управление на утайките",
        "contractor": "Община Своге",
        "typeObject": "ПСОВ на гр. Своге,общ. Своге"
    },
    {
        "number": "72",
        "name": "Изготвяне на работен проект, доставка на оборудванеи технологична сграда за ПСОВ за „Реконструкция имодернизация на Петролно-Складова база Илиенци“",
        "contractor": "Джи Пи Груп ООД, гр.София",
        "typeObject": "Нефтена база наЛукойл"
    },
    {
        "number": "73",
        "name": "Изготвяне на предпроектни поручвания, идеен проекти технически проект за „Реконструкция на Галваниченцех“",
        "contractor": "„Терем – ГеоргиБенковски“, гр. Пловдив",
        "typeObject": "Галваничен цех"
    },
    {
        "number": "74",
        "name": "Цялостно изпълнение на проект за „Реконструкция имодернизация на Пречиствателна станция заотпадъчни води“",
        "contractor": "“Дуропак Тракия” АД,гр.Пазарджик",
        "typeObject": "Хартиенапромишленост"
    },
    {
        "number": "75",
        "name": "Изготвяне на идеен проект за Интегриран проект заводен цикъл на град Велико Търново – реконструкцияна ПСОВ, реконструкция на канализационна иводопроводна мрежа",
        "contractor": "Община Велико Търново",
        "typeObject": "Водоснабдяване,канализация иПСОВ на гр. ВеликоТърново, общ.Велико Търново"
    },
    {
        "number": "76",
        "name": "Доставка, шеф – монтаж, пуск и наладка на машинно– технологично оборудване за модулнапречиствателна станция за битови отпадъчни води отканализационната система на с. Стамболово, общинаИхтиман",
        "contractor": "„Мирикал“ ЕООД, гр.София",
        "typeObject": "ПСОВ на с.Стамболово, общ.Ихтиман"
    },
    {
        "number": "77",
        "name": "Преработка на технически проект за изграждане наПСОВ на територията на „Дамяница“ АД",
        "contractor": "„Дамяница“ АД, с.Дамяница, общ.Сандански",
        "typeObject": "Винарна"
    },
    {
        "number": "78",
        "name": "Изготвяне на работни проекти за обекти: ПСОВ на с.Михнево и с. Габрене",
        "contractor": "Община Петрич",
        "typeObject": "ПСОВ на с.Михнево и с.Габрене, общ.Петрич"
    },
    {
        "number": "79",
        "name": "Изготвяне на работен проект за обект: ПСОВ на с.Кочево",
        "contractor": "Община Садово",
        "typeObject": "ПСОВ на с. Кочево,общ. Садово"
    },
    {
        "number": "80",
        "name": "Изготвяне на работен проект за обект: ПСОВ на с.Кочево – Външна Инфраструктура",
        "contractor": "Община Садово",
        "typeObject": "ПСОВ на с. Кочево,общ. Садово"
    },
    {
        "number": "81",
        "name": "Изготвяне на работни проекти за обекти: ПСОВ на с.Боголин, с. Долен, с. Фъргово, с. Годешево, с.Жижево, с. Крибул, с. Туховица, с. Осина ис.Ваклиново",
        "contractor": "Община Сатовча",
        "typeObject": "ПСОВ на с. Боголин,с. Долен, с. Фъргово,с. Годешево, с.Жижево, с. Крибул,с. Туховица, с.Осина, с. Ваклиново"
    },
    {
        "number": "82",
        "name": "Изготвяне на идеен и работен проекти за обект:ПСОВ на с. Ресилово",
        "contractor": "Община Сапарева баня",
        "typeObject": "ПСОВ на с.Ресилово, общ.Сепарева баня"
    },
    {
        "number": "83",
        "name": "Доставка, монтаж и пуск в експлоатация на ПСОВ за150 Е.Ж.",
        "contractor": "„Подеминженеринг“ООД, гр. София",
        "typeObject": "Жилищен комплекс"
    },
    {
        "number": "84",
        "name": "Доставка, монтаж и пуск в експлоатация на ПСОВ с.Крушовица, общ. Мизия, 1000 Е.Ж.",
        "contractor": "„Мирикал“ ЕООД, гр.София",
        "typeObject": "ПСОВ на с.Крушовица, общ.Мизия"
    },
    {
        "number": "85",
        "name": "Пречистване на инфилтрат от Претоварна станцияБалчик",
        "contractor": "„Нанкови“ ООД, гр.Балчик",
        "typeObject": "Претоварна станцияза отпадъци"
    },
    {
        "number": "86",
        "name": "6Пречистване на инфилтрат от регионално депо заотпадъци „Стожер“",
        "contractor": "Нанкови“ ООД, гр.Балчик",
        "typeObject": "Претоварна станцияза отпадъци"
    },
    {
        "number": "87",
        "name": "Проектиране, доставка и пуск в експлоатация напречиствателна станция за отпадъчни води за 50 Е.Ж.",
        "contractor": "„Техномаш-българскаиндустриална група“ АД,гр. Нова Загора",
        "typeObject": "Хотелски комплекс"
    },
    {
        "number": "88",
        "name": "Проектиране, доставка и пуск в експлоатация напречиствателна станция за отпадъчни води за 30 Е.Ж.",
        "contractor": "„Дрийм ХоумДивелъпмънт “ ООД, гр.София",
        "typeObject": "Жилищна сграда"
    },
    {
        "number": "89",
        "name": "Доставка, монтаж,пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води за 1120 Е.Ж., ПСОВ на с.Трилистник, общ. Марица",
        "contractor": "„Кераминженеринг “ АД,клон Багренци, с.Багренци, общ.Кюстендил",
        "typeObject": "ПСОВ на с.Трилисник, общ.Марица"
    },
    {
        "number": "90",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Глогово, общ. Тетевен, 1600ЕЖ",
        "contractor": "„Инженеринг ДоберсекГмбХ“, Германия",
        "typeObject": "ПСОВ на с. Глогово,общ. Тетевен"
    },
    {
        "number": "91",
        "name": "Доставка, монтаж и пуск и наладка на машинно- технологично оборудване на пречиствателна станцияза отпадъчни води на с. Кочево, общ. Садово, 500 Е.Ж.",
        "contractor": "„Инженеринг ДоберсекГмбХ“, Германия",
        "typeObject": "ПСОВ на с. Кочево,общ. Садово"
    },
    {
        "number": "92",
        "name": "Доставка, монтаж и пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Карабунар, общ. Септември,330 Е.Ж.",
        "contractor": "„Тарктур“ЕООД, гр.София",
        "typeObject": "ПСОВ на с.Карабунар, общ.Септември"
    },
    {
        "number": "93",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Ръжево Конаре , общ.Калояново, 2000 Е.Ж.",
        "contractor": "„Драгиев и Ко“ООД, гр.Пловдив",
        "typeObject": "ПСОВ на с. РъжевоКонаре, общ.Калояново"
    },
    {
        "number": "94",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Житница, общ. Калояново,1900 Е.Ж.",
        "contractor": "„Драгиев и Ко“ООД, гр.Пловдив",
        "typeObject": "ПСОВ на с.Житница, общ.Калояново"
    },
    {
        "number": "95",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Жижево, общ. Сатовча, 380Е.Ж.",
        "contractor": "„Агромах“ гр.Благоевград",
        "typeObject": "ПСОВ на с. Жижево,общ. Сатовча"
    },
    {
        "number": "96",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Долен, общ. Сатовча, 410 Е.Ж.",
        "contractor": "„Агромах“ гр.Благоевград",
        "typeObject": "ПСОВ на с. Долен,общ. Сатовча"
    },
    {
        "number": "97",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Туховища, общ. Сатовча, 890Е.Ж.",
        "contractor": "„Агромах“ гр.Благоевград",
        "typeObject": "ПСОВ на с.Туховища, общ.Сатовча"
    },
    {
        "number": "98",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Ваклиново, общ. Сатовча,1300 Е.Ж.",
        "contractor": "„Агромах“ гр.Благоевград",
        "typeObject": "ПСОВ на с.Ваклиново, общ.Сатовча"
    },
    {
        "number": "99",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Фъргово, общ. Сатовча, 500Е.Ж.",
        "contractor": "„Агромах“ гр.Благоевград",
        "typeObject": "ПСОВ на с.Фъргово, общ.Сатовча"
    },
    {
        "number": "100",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Оряховец, общ. Баните, 500Е.Ж.",
        "contractor": "„Станилов“ ЕООД, гр.София",
        "typeObject": "ПСОВ на с.Оряховец, общ.Баните"
    },
    {
        "number": "101",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Михнево, общ. Петрич, 1500Е.Ж.",
        "contractor": "„Агромах“ гр.Благоевград",
        "typeObject": "ПСОВ на с.Михнево, общ.Петрич"
    },
    {
        "number": "102",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Черниче, общ. Симитли, 1200Е.Ж.",
        "contractor": "„Агромах“ гр.Благоевград",
        "typeObject": "ПСОВ на с.Черниче, общ.Симитли"
    },
    {
        "number": "103",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза отпадъчни води на с. Габрене, общ. Петрич, 1000Е.Ж.",
        "contractor": "„Бонима“ ЕООД, гр.София",
        "typeObject": "ПСОВ на с. Габрене,общ. Петрич"
    },
    {
        "number": "104",
        "name": "Доставка, монтаж, пуск и наладка на машинно-технологично оборудване на пречиствателна станцияза битова отпадна вода и маслозадържатели заотделяне на петролни продукти от дъждовнатаканализация на Газохранилище „Чирен“, общ. Враца",
        "contractor": "„Мегатурс-1“ ЕООД, гр.София",
        "typeObject": "ГазохранилищеЧирен"
    },
    {
        "number": "105",
        "name": "Изготвяне на технически проект за обект: ПСОВ намесопреработвателно предприятие на фирма „Чех-Йосиф Новосад“ ООД, с. Соколово, общ. Дрянов",
        "contractor": "„Чех-Йосиф Новосад“ООД, с. Соколово",
        "typeObject": "Кланица и мандра"
    },
    {
        "number": "106",
        "name": "Изготвяне на идеен проект, технически консултации ипредоставяне на ноу-хау технология за обект: ПСОВ исистема за допречистване на пречистените водиформирани на територията на Югозападенуниверситер „Неофит Рилски“ , гр. Благоевград",
        "contractor": "Югозападен университер„Неофит Рилски“ , гр.Благоевград",
        "typeObject": "Университет"
    },
    {
        "number": "107",
        "name": "Доставка, монтаж, пуск и наладка на модулна ПСОВна предприятие за преработка на месо в с. Левуново,общ. Сандански",
        "contractor": "Мезе“ ЕООД, с.Левуново",
        "typeObject": "Преработка на месо"
    },
    {
        "number": "108",
        "name": "Доставка, монтаж, пуск и наладка на модулна ПСОВза 150 Е.Ж. на СПА хотел в с. Рупите, общ. Сандански",
        "contractor": "„Си Трейд“ гр. София",
        "typeObject": "Хотелски комплекс"
    },
    {
        "number": "109",
        "name": "Доставка, шеф-монтаж, пуск и наладка на машинно-технологично оборудване на ПСОВ намесопреработвателно предприятие на фирма „Чех-Йосиф Новосад“ ООД, с. Соколово, общ. Дряново",
        "contractor": "„Чех-Йосиф Новосад“ООД, с. Соколово",
        "typeObject": "Кланица и мандра"
    },
    {
        "number": "110",
        "name": "Доставка,монтаж,пуск и наладка на пречиствателна станция с капацитет 200 Е.Ж.",
        "contractor": "ИА Военни клубове и военно почивно дело",
        "typeObject": "Хотелски комплекс"
    },
    {
        "number": "111",
        "name": "Доставка и монтаж на оборудване на съществуваща пречиствателна станция на Млекопреработвателно предприятие – гр. Видин",
        "contractor": "„Кубар строй” ЕООД",
        "typeObject": "Млекопреработвателнопредприятие"
    },
    {
        "number": "112",
        "name": "Реконструкция и увеличаване на капацитета на ПСОВ, Доставка и монтаж на ПСОВ с капацитет 500 Е.Ж.",
        "contractor": "„Лайтхаус Голф Ризорт” АД, гр. Каварна",
        "typeObject": "Голф игрище"
    },
    {
        "number": "113",
        "name": "Доставка,монтаж,пуск и наладка на пречиствателна станция с капацитет 150 Е.Ж.",
        "contractor": " „ Бодиарт ” ЕООД",
        "typeObject": "Многофункционална сграда"
    }
]

const CompletedProjects = () => {
	return (
        <>
        <Navbar/>
		<main className={styles["completed-projects"]}>
			
            <Heading
            className={styles["completed-projects__header"]}
            headingClass={styles["completed-projects__heading"]}
            hrClass={styles["completed-projects__hr"]}
			title="Изпълнени обекти"
			/>



            <div className={styles["table-holder"]}>
                <table className={styles["table"]}>
                    <thead className={styles["thead"]}>
                        <tr className={`${styles["tr"]} ${styles["first"]} ${styles["odd"]}`}>
                            <th className={styles["th"]}>№</th>
                            <th className={styles["th"]}>Наименование на проект/обект и видове строително-монтажни работи</th>
                            <th className={styles["th"]}>Възложител</th>
                            <th className={styles["th"]}>Вид обект</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tableInfo.map((item, i) => {
                            return (
                                <tr key={i} className={`${styles["tr"]} ${i % 2 === 0 ? ` ${styles["odd"]}` : ` ${styles["even"]}`}`}>
                                    <td className={styles["td"]}>{item.number}</td>
                                    <td className={styles["td"]}>{item.name}</td>
                                    <td className={styles["td"]}>{item.contractor}</td>
                                    <td className={styles["td"]}>{item.typeObject}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
		</main>
		<Footer />
        <GoToTop />
        </>
	);
};

export default CompletedProjects;