import React from 'react';
import Navbar from '../components/navbar/navbar';
import Heading from '../components/heading/heading';
import Gallery from '../components/gallery/gallery';
import styles from './css/mbrAndMbbr.module.css'
import FaqElement from '../components/faqElement/faqElement';
import Footer from '../components/footer/footer';
import GoToTop from '../components/goToTop/goToTop';

const MBRAndMBBR = () => {
    
    const faq = [
        {
            question: "MBR технология за пречистване на отпадни води от нефтохимическата промишленост",
            answer:
            <div>
                <p>MBR (Membrane Bioreactor) технологията представлява високоефективен метод за пречистване на отпадните води от нефтохимическата промишленост. Тази технология комбинира биологичната обработка с употребата на мембрани, които задържат течни и твърди частици, бактерии и вируси, гарантирайки, че водата, която се изхвърля в околната среда или се рециклира за повторна употреба, е високо почистена и безопасна.</p>
                <h3>Предизвикателства в отпадните води от нефтохимическата промишленост</h3>
                <ul>
                    <li>Хидроуглероди и нефтени вещества: Отпадните води съдържат хидроуглероди и нефтени вещества, които са трудни за разграждане и изискват специализирани методи за обработка.</li>
                    <li>Химикали и тежки метали: Промишлените отпадни води често съдържат химикали и тежки метали, които са токсични за околната среда и човешкото здраве.</li>
                    <li>Биологични замърсители: Водите могат да съдържат биологични замърсители като бактерии и вируси, които са опасни за човешкото здраве.</li>
                </ul>

                <h3>Как MBR технологията решава тези предизвикателства</h3>
                <ul>
                    <li>Биологична обработка: MBR системата включва биологични процеси, при които специфични микроорганизми разграждат хидроуглеродите и органичните замърсители в отпадните води.</li>
                    <li>Филтрация чрез мембрани: Мембраните в MBR системата задържат течни и твърди частици, бактерии и вируси, позволявайки само чистата вода да мине, което осигурява високо качество на пречистването.</li>
                    <li>Осигуряване на стабилни операции: MBR технологията осигурява стабилни и надеждни операции за пречистване на отпадните води, като гарантира постоянно високо качество на обработката.</li>
                    <li>Флексибилност и адаптивност: MBR системите са гъвкави и могат да бъдат адаптирани към различни видове нефтохимическа промишленост, което позволява бързо реагиране на промените в състава на отпадните води.</li>
                    <li>Възможност за рециклиране на водата: Чистата вода, получена след преминаване през MBR системата, може да бъде рециклирана за различни производствени процеси, което подпомага в устойчивата употреба на водните ресурси.</li>
                </ul>

                <p>MBR технологията предоставя високоефективно и надеждно решение за пречистване на отпадните води от нефтохимическата промишленост, което помага за спазването на стандартите за околната среда и подпомага устойчивите производствени практики в този сектор.</p>
            </div>
        },
        {
            question: "MBR технология за пречистване на отпадни води от производство на олио и растителни масла",
            answer:
            <div>
                <p>MBR (Membrane Bioreactor) технологията е изключително ефективен метод за пречистване на отпадните води от производството на олио и растителни масла. Производството на тези продукти създава значителни количества отпадни води, които съдържат органични и неорганични замърсители, които трябва да бъдат обработени преди изхвърлянето им в околната среда или рециклирането им за повторна употреба.</p>

                <h3>Предизвикателства в отпадните води от производство на олио и растителни масла</h3>
                <ul>
                    <li>Органични замърсители: Отпадните води съдържат остатъци от растения, мазнини и други органични вещества, които са трудни за разграждане.</li>
                    <li>Неорганични замърсители: Промишлените отпадни води често съдържат неорганични вещества, като тежки метали и химикали.</li>
                    <li>Биологични замърсители: Отпадните води могат да съдържат бактерии и микроорганизми, които трябва да бъдат обезвредени.</li>
                </ul>

                <h3>Как MBR технологията решава тези предизвикателства</h3>
                <ul>
                    <li>Биологична обработка: MBR системата включва биологични процеси, при които специфични микроорганизми разграждат органичните вещества в отпадните води.</li>
                    <li>Филтрация чрез мембрани: Мембраните в MBR системата задържат течни и твърди частици, бактерии и вируси, като позволяват само чистата вода да мине през тях. Този процес осигурява високо ниво на пречистване.</li>
                    <li>Отстраняване на тежките метали и химикалите: MBR технологията може да бъде комбинирана с химически процеси за обезвреждане на тежките метали и химикалите в отпадните води, осигурявайки комплексно и надеждно пречистване.</li>
                    <li>Осигуряване на стабилни операции: MBR технологията е стабилна и предсказуема, като осигурява непрекъснат и висококачествен процес на обработка.</li>
                    <li>Възможност за рециклиране на водата: Чистата вода, която се получава след обработка чрез MBR системата, може да бъде рециклирана за водоподаване на производствения процес, което помага за устойчивата употреба на водните ресурси.</li>
                </ul>

                <p>MBR технологията представлява надеждно и ефективно решение за пречистване на отпадните води от производството на олио и растителни масла, като същевременно спомага за спазването на стандартите за околната среда и насърчава устойчиви производствени практики в тази индустрия.</p>
            </div>
        },
        {
            question: "MBR технология за пречистване на отпадни води от галванично производство",
            answer:
            <div>
                <p>MBR (Membrane Bioreactor) технологията представлява високоефективен и иновативен метод за пречистване на отпадните води от галваничната промишленост. Галваничното производство включва обработка на метални повърхности с помощта на химикали, което често води до образуване на отпадни води с високо съдържание на тежки метали и химикали. Тези замърсители са токсични за околната среда и изискват внимателно и ефикасно пречистване, преди отпадната вода да бъде изхвърлена в околната среда или рециклирана за повторна употреба.</p>

                <h3>Предизвикателства в отпадните води от галваничното производство</h3>
                <ul>
                    <li>Тежки метали: Отпадните води често съдържат тежки метали като свинец, кадмий и хром, които са токсични и изключително опасни за околната среда и човешкото здраве.</li>
                    <li>Химикали: Химикалите, използвани в галваничния процес, също представляват сериозен замърсител, който трябва да бъде обезвреден преди изхвърлянето на отпадната вода.</li>
                </ul>

                <h3>Как MBR технологията решава тези предизвикателства</h3>
                <ul>
                    <li>1.	Биологична обработка на тежките метали: MBR системите включват биологични процеси, които могат да обработват и устойчиво да унищожат тежките метали чрез специфични бактерии и микроорганизми.</li>
                    <li>2.	Филтрация чрез мембрани: Мембраните в MBR системата задържат течни и твърди частици, включително тежките метали и химикалите, гарантирайки чистата вода да премине през тях.</li>
                    <li>3.	Осигуряване на стабилни и надеждни операции: MBR технологията осигурява стабилни и предсказуеми операции за пречистване на отпадните води, като същевременно предоставя високо ниво на обработка и безопасност.</li>
                    <li>4.	Възможност за рециклиране на водата: Чистата вода, която се получава след обработка чрез MBR системата, може да бъде рециклирана за производствените процеси, намалявайки така нуждата от пресни водни източници.</li>
                </ul>

                <p>MBR технологията представлява ефективно и устойчиво решение за пречистване на отпадните води от галваничната промишленост, като същевременно помага за спазването на стандартите за околната среда и подпомага устойчивите производствени практики в тази индустрия.</p>
            </div>
        },
        {
            question: "MBBR технология за пречистване на отпадни води от хартия и целулоза",
            answer:
            <div>
                <p>MBBR (Moving Bed Biofilm Reactor) технологията представлява отличен избор за пречистване на отпадни води от производствата на хартия и целулоза. Този метод комбинира биологичен процес за обработка със специални подвижни биофилм носители, които активно премахват замърсителите в отпадните води. Ето как MBBR технологията може да бъде приложена успешно в този контекст:</p>
                <h3>Предизвикателства в отпадните води от хартия и целулоза</h3>
                <ul>
                    <li>Органични замърсители: Отпадните води от производствата на хартия и целулоза често съдържат високи нива на органични замърсители като целулозни фрагменти, лингин, хемикали и други органични съединения.</li>
                    <li>Твърди частици: Производствените процеси могат да генерират твърди частици и суспендирани материали, които трябва да бъдат ефективно отстранени от отпадните води.</li>
                </ul>

                <h3>Как MBBR технологията решава тези предизвикателства:</h3>
                <ul>
                    <li>Биологична разграждане на органичните замърсители: В MBBR реактора бактериите, които образуват биофилм върху подвижните носители, активно разграждат сложните органични замърсители в отпадните води, превръщайки ги в по-прости и безопасни съединения.</li>
                    <li>Ефективно задържане на твърдите частици: Подвижните носители и биологичният филм на повърхността им играят ключова роля в задържането на твърдите частици и материали в отпадните води, преди те да бъдат отстранени от системата.</li>
                    <li>Гъвкавост и лесна поддръжка: MBBR системите са гъвкави и лесни за управление и поддръжка, което е важно в индустриални производства, където условията могат да варират.</li>
                    <li>Намаляване на химикалите: За разлика от някои други методи, MBBR технологията използва по-малко или дори нула химикали, намалявайки така опасността за околната среда.</li>
                </ul>
                
                <p>MBBR технологията е идеално решение за производства на хартия и целулоза, тъй като тя не само намалява замърсяването на водните източници, но и предоставя възможност за повторно използване на водата в производствените процеси, като същевременно спомага за спазването на регулаторните стандарти за околната среда.</p>
            </div>
        },
        {
            question: "MBBR технология за пречистване на отпадни води от преработка на мляко и млечни продукти",
            answer:
            <div>
                <p>MBBR технологията е идеално решение за производства на хартия и целулоза, тъй като тя не само намалява замърсяването на водните източници, но и предоставя възможност за повторно използване на водата в производствените процеси, като същевременно спомага за спазването на регулаторните стандарти за околната среда.</p>
                <h3>Предизвикателства в отпадните води от преработка на мляко и млечни продукти:</h3>
                <ul>
                    <li>
                        Органични замърсители: Отпадните води от млечната промишленост съдържат високи нива на органични замърсители като млечни захари, мазнини и протеини.
                    </li>
                    <li>
                        Наличие на бактерии: Преработката на мляко и млечни продукти може да предизвика наличие на бактерии в отпадните води, които трябва да бъдат ефективно унищожени.
                    </li>
                    <li>
                        Намаление на ароматите и вкусовете: Отпадните води могат да съдържат аромати и вкусове, които трябва да бъдат обработени, за да не замърсят околната среда.
                    </li>
                </ul>

                <h3>Как MBBR технологията решава тези предизвикателства</h3>
                <ul>
                    <li>
                        Биологична разграждане на органичните замърсители: В MBBR реактора, подвижните биофилм носители предоставят обширна повърхност за растеж на бактерии, които бързо разграждат органичните замърсители, включително млечните захари и мазнините.
                    </li>
                    <li>
                        Унищожаване на бактерии: Биологичната обработка в MBBR реактора пречиства отпадните води от бактерии и други микроорганизми чрез биологични процеси.
                    </li>
                    <li>
                        Филтрация на аромати и вкусове: Подвижните биофилм носители и мембраните за филтриране в MBBR технологията спомагат за задържането на течни и твърди частици, включително аромати и вкусове, преди те да бъдат отстранени от водата.
                    </li>
                    <li>
                        Специализирана конфигурация: MBBR системата може да бъде конфигурирана специално за нуждите на млечната промишленост, осигурявайки оптимално пречистване на отпадните води.
                    </li>
                    <li>
                        Лесна поддръжка и управление: MBBR системите са гъвкави, лесни за управление и поддръжка, което е ключово в индустриални среди, където операциите са непрекъснати.
                    </li>
                </ul>

                <p>MBBR технологията предоставя ефективно и надеждно пречистване на отпадните води от производството на мляко и млечни продукти, което не само спомага за съответствие с регулаторните стандарти, но и осигурява опазване на околната среда и устойчиви производствени практики.</p>
            </div>
        },
        {
            question: "MBBR технология за пречистване на инфилтрат от депа за общински отпадъци",
            answer:
            <div>
                <p>MBBR (Moving Bed Biofilm Reactor) технологията представлява високоефективен метод за пречистване на инфилтрат от депа за общински отпадъци. Инфилтратът е водата, която преминава през отпадъците в депата и събира различни замърсители и химикали. Прочистването на този вид отпадъчна вода е от критично значение за предотвратяване на замърсяването на подземните и повърхностни води, както и за спазване на околната среда и общественото здраве. Ето как MBBR технологията може да бъде приложена за успешното пречистване на инфилтрат от депа за общински отпадъци:</p>
                <h3>Предизвикателства в инфилтрата от депа за общински отпадъци</h3>
                <ul>
                    <li>Органични и неорганични замърсители: Инфилтратът съдържа различни органични и неорганични замърсители като тежки метали, химикали, масла и т.н.</li>
                    <li>Високо ниво на разтворени твърди вещества: Инфилтратът често съдържа високи нива на разтворени твърди вещества, които трябва да бъдат ефективно отстранени.</li>
                </ul>

                <h3>Как MBBR технологията решава тези предизвикателства</h3>
                <ul>
                    <li>Биологична разграждане на органичните замърсители: MBBR системата използва подвижни биофилм носители, към които се атакуват бактерии и микроорганизми. Тези биофилм носители служат като платформа, където органичните замърсители се разграждат биологично.</li>
                    <li>Филтриране на твърдите вещества: Подвижните биофилм носители и мембраните, ако са включени в системата, могат да филтрират твърдите вещества и суспендирани частици, осигурявайки по-чиста вода.</li>
                    <li>Специализирано конфигуриране: MBBR системата може да бъде конфигурирана и оптимизирана специално за нуждите на инфилтрата от депата за общински отпадъци, като се гарантира максимална ефективност на обработката.</li>
                    <li>Намаляване на нивата на тежките метали и химикалите: Процесът на биологична обработка в MBBR системата помага за намаляване на нивата на тежките метали и химикалите във водата.</li>
                    <li>Лесна поддръжка и управление: MBBR системите са лесни за управление и поддръжка, като същевременно се гарантира стабилна и надеждна работа на системата.</li>
                </ul>

                <p>MBBR технологията предоставя ефективно, устойчиво и икономически изгодно решение за пречистване на инфилтрат от депа за общински отпадъци, което спомага за опазването на околната среда и общественото здраве.</p>
            </div>
        },
        {
            question: "MBBR технология за пречистване на отпадни води от кланници, птицеферми и преработка на месо",
            answer:
            <div>
                <p>MBBR (Moving Bed Biofilm Reactor) технологията представлява ефективен и устойчив метод за пречистване на отпадните води от кланници, птицеферми и преработка на месо. Тези индустриални операции създават значителни количества отпадни води, които често съдържат високи нива на органични и биологични замърсители. Ефикасното им пречистване е от съществено значение за спазване на стандартите за околната среда и предотвратяване на замърсяването на водните ресурси. Ето как MBBR технологията може да бъде успешно приложена в този контекст:</p>
                <h3>Предизвикателства в отпадните води от кланници, птицеферми и преработка на месо</h3>
                <ul>
                    <li>Органични замърсители: Отпадните води често съдържат кръв, мазнини, протеини и други органични съединения, които трябва да бъдат ефективно обработени.</li>
                    <li>Бактерии и микроорганизми: Производствените отпадни води често съдържат високи нива на бактерии и микроорганизми, които трябва да бъдат унищожени.</li>
                    <li>Твърди частици: Отпадните води могат да бъдат замърсени с твърди частици и суспендирани материали, които трябва да бъдат отстранени.</li>
                </ul>

                <h3>Как MBBR технологията решава тези предизвикателства</h3>
                <ul>
                    <li>Биологична разграждане на органичните замърсители: В MBBR реакторите, подвижните биофилм носители предоставят обширна повърхност за растеж на бактерии и микроорганизми. Тези бактерии разграждат сложните органични замърсители в по-прости и безопасни съединения.</li>
                    <li>Унищожаване на бактерии и микроорганизми: Процесът на биологична обработка в MBBR системата помага за унищожаване на бактерии и микроорганизми чрез биологични процеси.</li>
                    <li>Филтрация на твърдите частици: Подвижните биофилм носители и мембраните за филтриране (ако са включени) в MBBR системата могат да филтрират твърдите частици и суспендирани материали от водата.</li>
                    <li>Специализирано конфигуриране: MBBR системата може да бъде конфигурирана специално за нуждите на кланници, птицеферми и преработка на месо, гарантирайки максимална ефективност на обработката.</li>
                    <li>5.	Намаляване на химикалите: Процесът на биологична обработка в MBBR технологията минимизира използването на химикали, намалявайки така операционните разходи.</li>
                </ul>

                <p>MBBR технологията предоставя надеждно и икономически изгодно решение за пречистване на отпадните води от кланници, птицеферми и преработка на месо. Тя не само спомага за спазването на стандартите за околната среда, но и осигурява устойчиви производствени практики и опазва водните ресурси.</p>
            </div>
        },
        {
            question: "MBBR технология за пречистване на отпадни води от риба и рибни продукти",
            answer:
            <div>
                <p>MBBR (Moving Bed Biofilm Reactor) технологията представлява ефективен и иновативен метод за пречистване на отпадните води от риба и рибни продукти производства. Този метод комбинира биологичната обработка с използването на подвижни биофилм носители, които активно разграждат органичните замърсители в отпадните води. Ето как MBBR технологията може да бъде успешно приложена в този контекст:</p>
                
                <h3>Предизвикателства в отпадните води от рибната индустрия</h3>
                <ul>
                    <li>Органични замърсители: Отпадните води съдържат остатъци от риба, белтъчини, мазнини и други органични вещества.</li>
                    <li>Бактерии и микроорганизми: Производствените отпадни води често съдържат високи нива на бактерии и микроорганизми.</li>
                    <li>Амониак и нитрати: Отпадните води могат да съдържат високи концентрации на амониак и нитрати, които са вредни за околната среда.</li>
                </ul>

                <h3>Как MBBR технологията решава тези предизвикателства</h3>
                <ul>
                    <li>Биологична разграждане на органичните замърсители: В MBBR реакторите, подвижните биофилм носители предоставят обширна повърхност за растеж на бактерии и микроорганизми. Тези бактерии активно разграждат органичните замърсители, включително белтъчините и мазнините, превръщайки ги в безопасни съединения.</li>
                    <li>Унищожаване на бактерии и микроорганизми: Процесът на биологична обработка в MBBR системата помага за унищожаване на бактерии и микроорганизми чрез биологични процеси.</li>
                    <li>Намаляване на амониака и нитратите: Бактериите в MBBR системата могат да превръщат амониака в нитрати чрез нитрификационни процеси, което намалява нивата на амониак и нитрати в отпадните води.</li>
                    <li>Специализирано конфигуриране: MBBR системата може да бъде конфигурирана специално за нуждите на рибната индустрия, гарантирайки оптимална ефективност на обработката.</li>
                    <li>Лесна поддръжка и управление: MBBR системите са гъвкави, лесни за управление и поддръжка, осигурявайки стабилна и надеждна работа на системата.</li>
                </ul>

                <p>MBBR технологията предоставя надеждно и икономически изгодно решение за пречистване на отпадните води от рибната индустрия, като спомага за спазването на стандартите за околната среда и осигурява устойчиви производствени практики в този сектор.</p>
            </div>
        },
        {
            question: "MBBR технология за пречистване на отпадни води от вино производство",
            answer:
            <div>
                <p>MBBR (Moving Bed Biofilm Reactor) технологията е изключително ефективен метод за пречистване на отпадните води от производството на вино. Производството на вино може да създаде значителни количества отпадни води, които съдържат различни замърсители, включително остатъци от плодове, захари, танини и други органични и неорганични вещества. Тези вещества трябва да бъдат ефективно обработени, преди отпадната вода да бъде изхвърлена в околната среда или да бъде рециклирана за повторна употреба в селското стопанство или други производствени процеси.</p>
                <p>Ето как MBBR технологията може да бъде успешно приложена за пречистване на отпадните води от вино производство:</p>

                <h3>Предизвикателства в отпадните води от вино производство</h3>
                <ul>
                    <li>Органични замърсители: Отпадните води съдържат остатъци от плодове, цветя и други растителни материали, които съдържат органични вещества.</li>
                    <li>Неорганични замърсители: Танини и други неорганични вещества могат също да бъдат присъстващи в отпадните води от вино производство.</li>
                    <li>Разтворени твърди вещества: Отпадните води могат да съдържат разтворени твърди вещества и суспендирани частици.</li>
                </ul>

                <h3>Как MBBR технологията решава тези предизвикателства</h3>
                <ul>
                    <li>Биологична разграждане на органичните замърсители: В MBBR системата, подвижните биофилм носители предоставят обширна повърхност за растеж на бактерии и микроорганизми. Тези бактерии активно разграждат органичните вещества в отпадната вода, превръщайки ги в по-прости и безопасни съединения.</li>
                    <li>Намаляване на неорганичните замърсители: Бактериите в MBBR системата могат да помагат при разграждането на неорганични вещества като танини чрез биологични процеси.</li>
                    <li>Филтрация на твърдите вещества: Подвижните биофилм носители и мембраните за филтриране (ако са включени) в MBBR системата могат да филтрират твърдите вещества и суспендирани частици от водата.</li>
                    <li>Специализирано конфигуриране: MBBR системата може да бъде конфигурирана специално за нуждите на вино производството, гарантирайки оптимална ефективност на обработката.</li>
                    <li>Лесна поддръжка и управление: MBBR системите са гъвкави, лесни за управление и поддръжка, като същевременно осигуряват стабилна и надеждна работа на системата.</li>
                </ul>

                <p>MBBR технологията предоставя надеждно и икономически изгодно решение за пречистване на отпадните води от вино производство, като спомага за спазването на стандартите за околната среда и подпомага устойчивите производствени практики в този сектор.</p>
            </div>
        }
    ]
    
    return (
        <>
        <Navbar />
        <main>
            <section className={styles["mbr-technology-section"]}>
                <Heading
                title="MBR технология" 
                headingClass={styles["mbr-technology-section__heading"]}
                hrClass={styles["mbr-technology-section__hr"]}
                />

                <div className={styles["mbr-technology-section__main"]}>
                    <div className={styles["mbr-technology-section__text"]}>
                        <p>
                            MBR (Membrane Bioreactor) технологията за пречистване на отпадни води
                            представлява иновативен и напреднал метод, който комбинира биологичен процес за
                            обработка с употребата на мембрани. Тази технология премахва замърсителите от отпадните
                            води, като използва биологична разграждане на замърсителите и филтриране чрез полупроницаеми
                            мембрани, които задържат твърдите частици и микроорганизмите.
                        </p>

                        <ul>
                            <li>
                                Биологична обработка – Отпадните води първо се подлагат на биологична обработка,
                                където специални бактерии и микроорганизми разграждат органичните замърсители.
                                Този процес се извършва в реактор, който съдържа биологични маси, подпомагани от
                                въздушни аератори, които осигуряват кислород на микроорганизмите.
                            </li>
                            
                            <li>
                                Мембранно филтриране – След биологичната обработка, отпадните води минават през
                                мембрани с микроскопични пори. Тези мембрани задържат твърдите частици, бактериите и
                                вирусите, позволявайки само чистата вода и разтворените минерали да преминат през тях.
                            </li>

                            <li>
                                Рециркулация и реинтеграция – Част от чистата вода се рециркулира обратно в процеса за
                                биологична обработка, за да подпомогне стабилността и ефективността на бактериите. Останалата
                                чиста вода може да бъде използвана за поливане, промишлени нужди или се излива в околната среда.
                            </li>
                        </ul>
                    </div>
                    <div className={styles["mbr-technology-section__gallery-holder"]}>
                        <Gallery imgNames ={["30.jpg","31.jpg","32.jpg"]} />
                    </div>
                </div>
            </section>

            <section className={styles["mbr-advantages-section"]}>
                <Heading
                headingClass={styles["mbr-advantages-section__heading"]}
                hrClass={styles["mbr-advantages-section__hr"]}
                title="Предимства на MBR технологията" />

                <div className={styles["mbr-advantages-section__main"]}>
                    <div className={styles["mbr-advantages-section__text"]}>
                        <ul>
                            <li>Висока ефективност при пречистване: MBR системите премахват почти всички замърсители,
                                включително бактерии, вируси и твърди частици, предоставяйки изключително чиста вода.
                            </li>
                            <li>
                                Компактност – Технологията изисква по-малко пространство в сравнение с традиционните системи
                                за пречистване на отпадни води.
                            </li>
                            <li>
                                Гъвкавост – Може да се прилага за различни видове отпадни води, включително комунални и промишлени отпадни води.
                            </li>
                            <li>
                                Контролиране на качеството на водата – Поради прецизното филтриране, MBR системите осигуряват високо качество на обработената вода.
                            </li>
                            <li>
                                Лесна поддръжка и управление – Технологията изисква по-малко химикали и има по-ниски операционни разходи.
                            </li>
                        </ul>
                    </div>
                    
                    <div className={styles["mbr-advantages-section__gallery-holder"]}>
						<Gallery imgNames ={["10.jpg", "11.jpg","22.jpg"]} />
                    </div>
                </div>
               
            </section>

            <section className={styles["mbbr-section"]}>

				<Heading
				headingClass={styles["mbbr-section__heading"]}
				hrClass={styles["mbbr-section__hr"]}
				title="Пречистване на отпадъчни води с MBBR технология" />
				
				<div className={styles["mbbr-section__main"]}>
					<div className={styles["mbbr-section__text"]}>
						<p>
							Biocon станции с MBBR представляват усъвършенстване на стандартния процес на пречистване предлаган от нашите 
							модулни пречиствателни станции Biocon Standart  и Biocon P&N Removal. MBBR (Moving Bed Biofilm Reactor) 
							представлява биобасейн с подвижни бионосители. Бионосителите са малки специлно направени съоражения, които 
							служат като ферма за микроорганизми. Така популацията на биорганизмите в биобасейнът се увеличава значително и 
							е по-стабилна. Това насищане с бактерии в биобасейна дава възможност за висока степен на пречистване на отпадната 
							вода и голяма стабилност на пречиствателния процес. MBBR технологията се предлага в контейнер или съоръжение изградено от бетон.
							Предлага се и в двата варианта:
						</p>
						<ul>
							<li>Biocon MBBR Standart – За стандартно пречистване на отпадните води без отстраняване на азот и фосфор</li>
							<li>Biocon MBBR P&N Removal – За пречистване на отпадни води с отстраняване на азот и фосфор.</li>
						</ul>
					</div>

					<div className={styles["mbbr-section-imgs-holder"]} >
						<Gallery imgNames ={["15.jpg","16.jpg","17.jpg"]} />
					</div>
				</div>
			</section>

			<section className={styles["mbbr-advantages-section"]}>
				<Heading
				title="Предимства на MBBR технологията"
				headingClass={styles["mbbr-advantages-section__heading"]}
				hrClass={styles["mbbr-advantages-section__hr"]}
				/>

				<div className={styles["mbbr-advantages-section__main"]}>
					<div className={styles["mbbr-advantages-section__text"]}>
						<ul>
							<li>Ефективен пречиствателен процес с до 5 пъти по-висока степен на пречистване в сравнение с традиционните методи</li>
							<li>Многократно намаляване на обема на пречиствателното съоръжение</li>
							<li>Пречиствателният капацитет на съоръжението може да се увеличи лесно, просто с добавянето на допълнително количество био носител.</li>
							<li>Устойчив пречиствателен процес, поемащ ударни натоварвания на отпадната вода</li>
							<li>Енергоспестяващ процес на пречистване</li>
							<li>Възможност за отстраняване на азот и фосфор</li>
							<li>Устойчивост към натоварване</li>
							<li>Изисква по-малко поддръжка</li>
							<li>Работи по-бързо и може да преработи повече вода</li>
						</ul>
					</div>


					<div className={styles["mbbr-advantages-section__gallery-holder"]}>
						<Gallery imgNames ={["19.jpg","20.jpg","35.jpg"]} />
					</div>

				</div>
			</section>

            <section className={styles["mbr-and-mbbr-section"]}>
                <Heading 
                headingClass={styles["mbr-and-mbbr-section__heading"]}
                hrClass={styles["mbr-and-mbbr-section__hr"]}
                title="Предимства на комбинацията MBBR и MBR технологии" />
                <div className={styles["mbr-and-mbbr-section__main"]}>
                    <div className={styles["mbr-and-mbbr-section__text"]}>
                        <ul>
                            <li>
                                Изключителна ефективност при биологичната обработка: MBBR технологията въвежда подвижни 
                                биофилм носители, които събират и разграждат замърсителите, ускорявайки биологичния процес 
                                на обработка.
                            </li>
                            <li>
                                Фината филтрация на MBR мембраните: MBR технологията използва микроскопични мембрани,
                                които задържат дори най-малките твърди частици, бактерии и вируси, предоставяйки чиста вода
                                с високо качество.
                            </li>
                            <li>
                                Гъвкавост и скалируемост: Комбинацията от MBBR и MBR технологии е гъвкава и може да бъде
                                адаптирана към различни мащаби на проекти, от малки комунални системи до големи индустриални обекти.
                            </li>
                            <li>
                                Оптимално управление на процесите: Комбинираният процес позволява по-голям контрол
                                върху обработката на отпадните води, като се осигурява стабилност и ефективност в дългосрочен план.
                            </li>
                            <li>
                                Намаляване на потреблението на химикали: За разлика от някои други методи, тази комбинация
                                минимизира използването на химикали, което помага за намаляване на операционните разходи.
                            </li>
                            <li>
                                Подходяща за разнообразни приложения: Комбинираната MBBR и MBR система може да бъде
                                успешно използвана за обработка на различни видове отпадни води, включително комунални,
                                промишлени и аграрни.
                            </li>
                        </ul>
                    </div>

                    <div className={styles["mbr-and-mbbr-section__gallery-holder"]}>
						<Gallery imgNames ={["33.jpg","34.jpg","21.jpg"]} />
                    </div>
                </div>
            </section>

            <section className={styles["application-in-industries-section"]}>
                <Heading
                headingClass={styles["application-in-industries-section__heading"]}
                hrClass={styles["application-in-industries-section__hr"]}
                title="Приложения в различните индустрии" />

                <div className={styles["application-in-industries-section__faq-elements-holder"]} >
                    {faq.map((item, i) => <FaqElement className={styles["application-in-industries-section__faq"]} key={i} question={item.question} answer={item.answer} />)}
                </div>

            </section>
        </main>
        <Footer/>
        <GoToTop/>
        </>
    )
}

export default MBRAndMBBR;
